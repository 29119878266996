// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function() {};
	var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeStamp', 'trace', 'warn'];
	var length = methods.length;
	var console = (window.console = window.console || {});

	while (length--) {
		method = methods[length];

		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
})();
// @codekit-prepend "../../../../../../node_modules/jquery/dist/jquery.js" quiet;
// @codekit-prepend "../../../../../../node_modules/imagesloaded/imagesloaded.pkgd.js" quiet;

// @codekit-prepend "cycle2/jquery.cycle2.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.swipe.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.autoheight.js" quiet;

// -----------------------------------------------------------------------------------------
jQuery.noConflict();
(function($) {

	function closeDialog(voting_success = false) {
		$('#dialog-container').fadeOut(100, function() {
			$('#dialog-container .loading').removeAttr('style');
		});
		$('body').removeClass('bodyfix');
		$('#nominee-details').remove();
		$('#voting-dialog').remove();

		if (voting_success === true) {
			var nominee_post_id = localStorage.getItem('nominee'); // // decode base64
			nominee_post_id = parseInt(atob(nominee_post_id));
			$('#nominee-' + nominee_post_id + ' .success-confirm-message').removeClass('hidden');
		}
	}

	function showNomineeDetailsDialog(post_id) {
		$.ajax({
			type: 'POST',
			url: voting_object.ajax_url,
			data: {
				action: 'nominee_details_dialog',
				nonce: voting_object.nonce,
				post_id: post_id,
			},
		}).done(function(data) {
			$('#dialog-container .loading').hide();
			console.log(data);
			$('#dialog-container').append(data);
			$('#dialog-slider').imagesLoaded(function() {
				$('#dialog-slider').cycle();
			});
		}).fail(function(xhr, textStatus, errorThrown) {
			console.error('Error:', xhr.statusText, xhr.responseText);
		});
	}

	function showVotingDialog(post_id, current_lang) {
		$.ajax({
			type: 'POST',
			url: voting_object.ajax_url,
			data: {
				action: 'nominee_voting_dialog',
				nonce: voting_object.nonce,
				post_id: post_id,
				current_lang: current_lang,
			},
		}).done(function(data) {
			$('#dialog-container .loading').hide();
			$('#dialog-container').append(data);
			window.initVotingSystem();
		}).fail(function(xhr, textStatus, errorThrown) {
			console.error('Error:', xhr.statusText, xhr.responseText);
		});
	}

	function storeVotingStatus(nominee_post_id) {
		localStorage.setItem('voted', 'true');
		nominee_post_id = btoa(nominee_post_id.toString()); // code base64
		localStorage.setItem('nominee', nominee_post_id);
	}

	function chechVotingStatus() {
		var voted = localStorage.getItem('voted') === 'true';
		var nominee_post_id = localStorage.getItem('nominee'); // // decode base64
		nominee_post_id = parseInt(atob(nominee_post_id));

		if (voted === true) {
			// console.log('Vote already done for: ' + nominee_post_id);
			disableVoting(nominee_post_id);
			$('#note-vote-already-done').removeClass('hidden');
			$('#nominees .vote-here').hide();
		}
	}

	function disableVoting(nominee_post_id = null) {
		$('#nominees .vote').addClass('hidden');
		$('#nominees .vote-here').hide();
		if (nominee_post_id) {
			$('#nominee-' + nominee_post_id).addClass('voted-nominee');
			$('#nominee-' + nominee_post_id).find('.choise').removeClass('hidden');
		}
	}

	chechVotingStatus();

	// close the menu with ESC key
	$(document).on('keyup', function(e) {
		if (e.keyCode == 27) {
			closeDialog();
		}
	});

	$(document).on('click', '#close-dialog', function() {
		closeDialog();
	});

	$('#nominees').on('click', '.nominee-details, figure img', function(e) {
		var post_id = $(this).closest('.nominee').data('nominee-id');
		$('body').addClass('bodyfix');
		$('#dialog-container').fadeIn(200, function() {
			showNomineeDetailsDialog(post_id);
		});
	});

	$('#nominees').on('click', '.vote-button', function() {
		var post_id = $(this).closest('.nominee').data('nominee-id');
		var current_lang = $('html').attr('lang');

		$('body').addClass('bodyfix');
		$('#dialog-container').fadeIn(200, function() {
			showVotingDialog(post_id, current_lang);
		});
	});

	$(document).on('click', '#submit-vote', function(e) {
		e.preventDefault();

		var nominee_post_id = $('#voting-dialog').data('nominee-post-id');
		disableVoting(nominee_post_id);
		storeVotingStatus(nominee_post_id);
		closeDialog();
	});


	// copyright function ------------------------------
	$(document).on('click', '.copyright-button', function() {
		$(this).next('.copyright-layer').fadeIn(200);
		$(this).fadeOut(100);
		$('.cycle-slideshow').cycle('pause');
	});

	$(document).on('click', '.copyright-close', function() {
		$(this).parent('.copyright-layer').fadeOut(100);
		$(this).closest('.image-wrapper').find('.copyright-button').fadeIn(100);
		$('.cycle-slideshow').cycle('resume');
	});

	// -------------------------------------------------



	window.disableVoting = disableVoting;
	window.storeVotingStatus = storeVotingStatus;
	window.closeDialog = closeDialog;

	// ------------------------------------------------------------------


})(jQuery);